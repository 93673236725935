import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('jobs-slider')[0]) {
  const jobsSlidersArr = document.querySelectorAll('.jobs-slider');
  jobsSlidersArr.forEach((sliderEl, sliderIdx) => {
    const paginationArr = document.querySelectorAll(
        '.jobs-slider-pagination'
    );

    const slides = sliderEl.querySelectorAll('.swiper-slide');
    let slidesCount = slides.length;

    const jobsSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation, Pagination],
      slidesPerView: 1,
      spaceBetween: 20,
      threshold: 10,
      loop: slidesCount > 1,
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: sliderEl.querySelector('.jobs-slider-arrow-btn-prev'),
        nextEl: sliderEl.querySelector('.jobs-slider-arrow-btn-next'),
      },
      pagination: {
        el: paginationArr[sliderIdx],
        clickable: true,
      },
      breakpoints: {
        551: {
          slidesPerView: 2,
          loop: slidesCount > 2,
          pagination: {
            el: paginationArr[sliderIdx],
            clickable: true,
          },
        },
        901: {
          slidesPerView: 3,
          loop: slidesCount > 3,
          pagination: {
            el: paginationArr[sliderIdx],
            clickable: true,
          },
        },
        1261: {
          slidesPerView: 4,
          loop: slidesCount > 4,
          pagination: {
            el: paginationArr[sliderIdx],
            clickable: true,
          },
        },
        1800: {
          slidesPerView: 5,
          loop: slidesCount > 5,
          pagination: {
            el: paginationArr[sliderIdx],
            clickable: true,
          },
        },
      },
      on: {
        breakpoint: function (swiper) {
          let breakpoint = this.currentBreakpoint;
          let breakpointConfig = this.passedParams.breakpoints[breakpoint];

          if (breakpointConfig && breakpointConfig.loop) {
            swiper.$el[0].querySelector('.swiper-wrapper').classList.remove('justify-center');
          } else {
            if (swiper.$el[0].querySelector('.swiper-slide-duplicate')) {
              swiper.$el[0].querySelector('.swiper-wrapper').classList.remove('justify-center');
            } else {
              if(window.innerWidth >= 1024) {
                swiper.$el[0].querySelector('.swiper-wrapper').classList.add('justify-center');
              }
            }
          }
        }
      }
    });

    swiperObserver(jobsSwiper);
  });
}
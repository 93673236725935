import './utils/vh.utils.js';
import './utils/safari.utils.js';

import './utils/animate-on-view.utils.js';
import './components/inputs.component.js';
import './components/uploadFile.component.js';

import './components/player.component.js';
import './components/jobs.component.js';
import './components/news.component.js';
import './components/teams.component.js';
import './components/testimonials.component.js';
import './components/logos.component.js';

import 'simplebar';

import SimpleBar from 'simplebar';

const burgerBtn = document.querySelector('.menu-button.w-nav-button');
if(burgerBtn) {
  burgerBtn.addEventListener('click', () => {
    setTimeout(function () {
      if(burgerBtn.classList.contains('w--open')) {
        scrollLock.disablePageScroll(document.querySelector('.nav-menu.w-nav-menu'));
        scrollLock.addScrollableSelector('.simplebar-content-wrapper');
      } else {
        scrollLock.enablePageScroll();
      }
    }, 50)
  })
}

const header = document.getElementsByClassName('navbar')[0];
const menu = header.querySelector('.nav-menu');

if(header){
  document.documentElement.style.setProperty('--header-height', `${header.clientHeight}px`);
  window.addEventListener('scroll', ()=>{document.documentElement.style.setProperty('--header-height', `${header.clientHeight}px`);})
}


if(window.innerWidth <= 451){
    new SimpleBar(menu, {
      autoHide: false,
    });

}




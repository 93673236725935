import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('teams-slider')[0]) {
  const newsSlidersArr = document.querySelectorAll('.teams-slider');
  newsSlidersArr.forEach((sliderEl, sliderIdx) => {
    const paginationArr = document.querySelectorAll(
        '.teams-slider-pagination'
    );
    const prevBtnArr = document.querySelectorAll('.teams-slider-arrow-btn-prev');
    const nextBtnArr = document.querySelectorAll('.teams-slider-arrow-btn-next');

    const slides = sliderEl.querySelectorAll('.swiper-slide');
    /*let loop = false;
    let loopedSlides = false;

    if (slides.length > 3) {
      loop = true;
      loopedSlides = slides.length * 3;
    }*/

    const teamsSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation, Pagination],
      slidesPerView: 1,
      spaceBetween: 20,
      threshold: 10,
/*      loop: loop,
      loopedSlides: loopedSlides,*/
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: prevBtnArr[sliderIdx],
        nextEl: nextBtnArr[sliderIdx],
      },
      pagination: {
        el: paginationArr[sliderIdx],
        clickable: true,
      },
      breakpoints: {
        651: {
          slidesPerView: 2,
        },
        1100: {
          slidesPerView: 3,
        },
      },
    });

    swiperObserver(teamsSwiper);
  });
}

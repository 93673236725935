import Swiper, { Autoplay, Navigation} from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('testimonials-slider')[0]) {
  const testimonialsSlidersArr = document.querySelectorAll('.testimonials-slider');
  testimonialsSlidersArr.forEach((sliderEl, sliderIdx) => {
    const slides = sliderEl.querySelectorAll('.swiper-slide');
    let slidesCount = slides.length;

    const testimonialsSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 20,
      threshold: 10,
      loop: slidesCount > 2,
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: sliderEl.querySelector('.testimonials-slider-arrow-btn-prev'),
        nextEl: sliderEl.querySelector('.testimonials-slider-arrow-btn-next'),
      },
    });

    swiperObserver(testimonialsSwiper);
  });
}
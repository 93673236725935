function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}
if (isSafari()) {
  const mainElement = document.getElementsByClassName('main')[0];
  const footerElement = document.getElementsByClassName('footer')[0];
  mainElement.classList.add('is-safari');

  if (footerElement) {
    footerElement.classList.add('is-safari');
  }
}

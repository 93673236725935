import Swiper, {Autoplay, Grid, Navigation} from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('logos-slider')[0]) {
  const logosSlidersArr = document.querySelectorAll('.logos-slider');
  logosSlidersArr.forEach((sliderEl, sliderIdx) => {
    const slides = sliderEl.querySelectorAll('.swiper-slide');
    let slidesCount = slides.length;

    const logosSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation, Grid],
      slidesPerView: 2,
      spaceBetween: 20,
      grid: {
        rows: 2,
      },
      threshold: 10,
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: sliderEl.querySelector('.logos-slider-arrow-btn-prev'),
        nextEl: sliderEl.querySelector('.logos-slider-arrow-btn-next'),
      },

      breakpoints: {

        451: {
          slidesPerView: 3,
          spaceBetween: 20,
          grid: {
            rows: 2,
          },
        },

      },
    });

    swiperObserver(logosSwiper);
  });
}